import {
  ConfirmationResponse,
  ResetPasswordResponse,
  SignInResponse,
} from '@module/user';

// Common
export const errorMessageSelector = (state: any): string => state.auth.error;

// Sign In
export const loadingSignInSelector = (state: any): boolean =>
  state.auth.isLoadingSignIn;
export const signInResponseSelector = (state: any): SignInResponse =>
  state.auth.signInResponse;
export const signInByGoogleResponseSelector = (state: any): SignInResponse =>
  state.auth.signInByGoogleResponse;
export const loadingSignInGoogleSelector = (state: any): boolean => state.auth.isLoadingSignInByGoogle;
export const signInStatusSelector = (state: any): boolean =>
  state.auth.isSignInSuccess;
export const signInGoogleSuccessStatusSelector = (state: any): boolean => state.auth.isSignInByGoogleSuccess;

// Sign Up
export const loadingSignUpSelector = (state: any): boolean =>
  state.auth.isLoadingSignUp;
export const signUpStatusSelector = (state: any): boolean =>
  state.auth.isSignUpSuccess;

// Forgot Password
export const loadingSendForgotPasswordEmailSelector = (state: any): boolean =>
  state.auth.isLoadingSendForgotPasswordEmail;
export const sendForgotPasswordStatusSelector = (state: any): boolean =>
  state.auth.isForgotPasswordEmailSuccess;
export const sendEmailForForgotPasswordStatusSelector = (state: any): number =>
  state.auth.sendEmailForForgotPasswordStatus;
// Confirmation
export const confirmationResponseSelector = (
  state: any,
): ConfirmationResponse => state.auth.confirmationResponse;
export const confirmationStatusSelector = (state: any): boolean =>
  state.auth.isConfirmationSuccess;

// Reset Password
export const loadingResetPasswordSelector = (state: any): boolean =>
  state.auth.isLoadingResetPassword;
export const resetPasswordStatusSelector = (state: any): boolean =>
  state.auth.isResetPasswordSuccess;
export const resetPasswordResponseSelector = (
  state: any,
): ResetPasswordResponse => state.auth.resetPasswordResponse;
