import { Action, createReducer } from '@lib/plugin-redux-core';
import { UpdateUserProfileUseCaseInput, User } from '@module/user';
import { UseCaseError } from '@smart-form/domains';
import {
  GET_USER_BY_ID_USE_CASE,
  UPDATE_PROFILE_USE_CASE,
  CHANGE_PASSWORD_USE_CASE,
} from '../../redux/root.action';
import {
  handleChangePasswordUseCase,
  handleGetUserByIdUseCase,
  handleUpdateProfileUseCase,
} from './account-setting.handler';

export enum AccountSettingStatus {
  EXECUTING = 1,
  SUCCESS = 2,
  ERROR = 3,
  RESET = 4,
}

type AccountSettingInitialState = {
  error?: UseCaseError<any>;
  payload: any;
};

type GetUserInformationUseCaseState = {
  userResponse: User;
  getUserInformationStatus: AccountSettingStatus;
};

type UpdateProfileUseCaseState = {
  updateProfileStatus: AccountSettingStatus;
  updateProfileInput: UpdateUserProfileUseCaseInput;
};

type ChangePasswordUseCaseState = {
  updatePasswordStatus: AccountSettingStatus;
};

export type AccountSettingPageState = AccountSettingInitialState &
  GetUserInformationUseCaseState &
  UpdateProfileUseCaseState &
  ChangePasswordUseCaseState;

const initialState: AccountSettingInitialState = {
  error: null,
  payload: null,
};

export const accountSettingReducer = createReducer(initialState, [
  {
    name: GET_USER_BY_ID_USE_CASE,
    ...handleGetUserByIdUseCase,
  },
  {
    name: UPDATE_PROFILE_USE_CASE,
    ...handleUpdateProfileUseCase,
  },
  {
    name: CHANGE_PASSWORD_USE_CASE,
    ...handleChangePasswordUseCase,
  },
]);
