import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, createEpicForUseCase, success } from '@lib/plugin-redux-core';
import { bootstrapAuthDI, bootstrapDI, UseCaseConfig } from '../di/index';
import { showToastHandler } from '@lib/common';
import {
  CHANGE_PASSWORD_USE_CASE,
  UPDATE_PROFILE_USE_CASE,
  RESET_PASSWORD_USE_CASE,
} from './root.action';
import { notification } from 'antd';

export const epic$ = new BehaviorSubject(combineEpics());
export const rootEpic = (action$: any, state$: any) =>
  epic$.pipe(mergeMap((epic) => epic(action$, state$, null)));

const cachedUseCases: UseCaseConfig[] = [];
const cachedAuthUseCases: UseCaseConfig[] = [];

export const runAsyncEpics = async () => {
  if (cachedUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapDI();
  cachedUseCases.push(...useCases);
  const epics = cachedUseCases.map((u: UseCaseConfig) => {
    return mappingShowToastByUseCase(u);
  });
  epics.forEach((e) => epic$.next(e));
};

export const runAuthAsyncEpics = async () => {
  if (cachedAuthUseCases.length > 0) {
    return;
  }
  const useCases = await bootstrapAuthDI();
  cachedAuthUseCases.push(...useCases);
  const epics = cachedAuthUseCases.map((u: UseCaseConfig) => {
    return createEpicForUseCase(u.instance, u.name, showToastHandler);
  });
  epics.forEach((e) => epic$.next(e));
};

export const mappingShowToastByUseCase = (u: UseCaseConfig) => {
  switch (u.name) {
    case UPDATE_PROFILE_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showUpdateProfileToastHandler);
    case CHANGE_PASSWORD_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showUpdatePasswordToastHandler);
    case RESET_PASSWORD_USE_CASE:
      return createEpicForUseCase(u.instance, u.name, showResetPasswordToastHandler);
    default:
      return createEpicForUseCase(u.instance, u.name, showToastHandler);
  }
};

const showResetPasswordToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation ? translation('resetPasswordSuccess') : 'resetPasswordSuccess';
    notification['success']({
      message: 'Success',
      description: message,
      duration: 2,
    });

    return;
  }

  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: 'Error',
    description: message,
    duration: 5,
  });
};

const showUpdateProfileToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('updateProfileSuccess');
    notification['success']({
      message: 'Success',
      description: message,
      duration: 2,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: 'Error',
    description: message,
    duration: 5,
  });
};

const showUpdatePasswordToastHandler = (action: Action) => {
  const { type, error, translation } = action;

  if (type.includes(success)) {
    const message = translation('updatePasswordSuccess');
    notification['success']({
      message: 'Success',
      description: message,
      duration: 2,
    });

    return;
  }
  if (!error) return;

  const parseError = typeof error.error == 'string' ? error.error : error.error.message;
  const message = translation ? translation(parseError) : parseError;

  notification['error']({
    message: 'Error',
    description: message,
    duration: 5,
  });
};
