import {
  ChangePasswordUseCase,
  GetUserByIdUseCase,
  IUserRepository,
  UpdateProfileUseCase,
  USER_REPOSITORY,
} from '@module/user';
import {
  CHANGE_PASSWORD_USE_CASE,
  GET_USER_BY_ID_USE_CASE,
  UPDATE_PROFILE_USE_CASE,
} from '../../redux/root.action';

export const userUseCaseProviders = [
  {
    provide: GET_USER_BY_ID_USE_CASE,
    useFactory: (userRepository: IUserRepository) => {
      return new GetUserByIdUseCase(userRepository);
    },
    inject: [USER_REPOSITORY],
  },
  {
    provide: UPDATE_PROFILE_USE_CASE,
    useFactory: (userRepository: IUserRepository) => {
      return new UpdateProfileUseCase(userRepository);
    },
    inject: [USER_REPOSITORY],
  },
  {
    provide: CHANGE_PASSWORD_USE_CASE,
    useFactory: (userRepository: IUserRepository) => {
      return new ChangePasswordUseCase(userRepository);
    },
    inject: [USER_REPOSITORY],
  },
];
