import { combineReducers } from 'redux';
import { AccountSettingPageState, accountSettingReducer } from './account-setting';
import { authReducer } from './auth';
import { commonReducer } from './common';
import { GoogleCallbackPageState, googleCallbackPageReducer } from './google-callback';

export type RootReducerState = {
  auth: any;
  accountSetting: AccountSettingPageState;
  common: any;
  googleCallbackPage: GoogleCallbackPageState;
};

export const rootReducer = combineReducers({
  auth: authReducer,
  accountSetting: accountSettingReducer,
  common: commonReducer,
  googleCallbackPage: googleCallbackPageReducer,
});
