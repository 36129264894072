import { createReducer } from '@lib/plugin-redux-core';
import { GET_USER_BY_ID_USE_CASE, UPDATE_PROFILE_USE_CASE } from '@module/user';
import {
  handleGetUserByIdUseCase, handleUpdateUserInformationUseCase,
} from './google-callback.handler';
import {
  initialState,
} from './google-callback.state';

export const googleCallbackPageReducer = createReducer(initialState, [
  {
    name: GET_USER_BY_ID_USE_CASE,
    ...handleGetUserByIdUseCase,
  },
  {
    name: UPDATE_PROFILE_USE_CASE,
    ...handleUpdateUserInformationUseCase,
  },
]);
