import { NestFactory } from "@nestjs/core";
import { AppModule } from "./app.module";
import { useCaseProviders } from "./usecase-providers";
import { AuthModule } from "./auth.module";
import { authUseCaseProviders } from "./usecase-providers/auth.provider";
export interface UseCaseConfig {
  name: string;
  instance: any;
}

export const bootstrapDI = async (): Promise<UseCaseConfig[]> => {
  const app = await NestFactory.createApplicationContext(AppModule, {
    abortOnError: false,
    logger: false,
  });

  const useCases = useCaseProviders.map((u: any) => ({
    name: u.provide,
    instance: app.get(u.provide),
    showingToast: !u.showingToast ? true : u.showingToast,
  }));
  return useCases;
};

export const bootstrapAuthDI = async (): Promise<UseCaseConfig[]> => {
  const app = await NestFactory.createApplicationContext(AuthModule, {
    abortOnError: false,
    logger: false,
  });

  const authUseCases = authUseCaseProviders.map((u) => ({
    name: u.provide,
    instance: app.get(u.provide),
  }));

  return authUseCases;
};