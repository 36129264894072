import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import { apiProviders } from './api-providers';

@Module({
  providers: [
    ...apiProviders,
    authenticationRepositoryProvider,
    userWebRepositoryProvider,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
