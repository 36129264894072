import { Action } from '@lib/plugin-redux-core';
import { GoogleCallbackPageState, GoogleCallbackPageStatus } from './google-callback.state';
import { User } from '@module/user';

export const handleGetUserByIdUseCase = {
  executing: (state: GoogleCallbackPageState): GoogleCallbackPageState => {
    return {
      ...state,
      getUserByIdStatus: GoogleCallbackPageStatus.EXECUTING,
    };
  },
  success: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    const { payload } = action;

    return {
      ...state,
      userResponse: payload as User,
      getUserByIdStatus: GoogleCallbackPageStatus.SUCCESS,
    };
  },
  error: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    const { error } = action;
    return {
      ...state,
      getUserByIdStatus: GoogleCallbackPageStatus.ERROR,
      error,
    };
  },
};

export const handleUpdateUserInformationUseCase = {
  executing: (state: GoogleCallbackPageState): GoogleCallbackPageState => {
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.EXECUTING,
    };
  },
  success: (state: GoogleCallbackPageState): GoogleCallbackPageState => {
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.SUCCESS,
    };
  },
  error: (state: GoogleCallbackPageState, action: Action): GoogleCallbackPageState => {
    const { error } = action;
    return {
      ...state,
      updateUserInformationStatus: GoogleCallbackPageStatus.ERROR,
      error,
    };
  },
};
