import { clearStateReducer } from '@lib/common';
import {
  ConfirmationResponse,
  InvalidPasswordError,
  ResetPasswordResponse,
  SignInResponse,
} from '@module/user';
import { createReducer, Action } from '@lib/plugin-redux-core';

import {
  SIGN_IN_USE_CASE,
  SIGN_UP_USE_CASE,
  CONFIRMATION_USE_CASE,
  FORGOT_PASSWORD_USE_CASE,
  RESET_PASSWORD_USE_CASE,
  SIGN_IN_BY_GOOGLE_USE_CASE,
} from '../root.action';
import { REQUEST_STATUS } from '../../utils';


const initialState = {
  isLoadingSignIn: false,
  isLoadingSignUp: false,
  isLoadingSendForgotPasswordEmail: false,
  isLoadingResetPassword: false,
  signInResponse: {},
  signInByGoogleResponse: undefined,
  isConfirmationSuccess: false,
  isSignInSuccess: false,
  isSignUpSuccess: false,
  isForgotPasswordEmailSuccess: false,
  sendEmailForForgotPasswordStatus: REQUEST_STATUS.REQUEST,
  isResetPasswordSuccess: false,
  resetPasswordResponse: {},
  error: '',
};

export const authReducer = createReducer(initialState, [
  clearStateReducer(initialState),
  {
    name: SIGN_IN_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return { ...state, payload, isLoadingSignIn: true };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        signInResponse: payload as SignInResponse,
        isLoadingSignIn: false,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error: error,
        isLoadingSignIn: false,
      };
    },
  },
  {
    name: SIGN_IN_BY_GOOGLE_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return { ...state, payload, isLoadingSignInByGoogle: true, isSignInByGoogleSuccess: false };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        signInByGoogleResponse: payload as SignInResponse,
        isLoadingSignInByGoogle: false,
        isSignInByGoogleSuccess: true
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error: error,
        isLoadingSignInByGoogle: false,
        isSignInByGoogleSuccess: false
      };
    },
  },
  {
    name: SIGN_UP_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return { ...state, payload, isLoadingSignUp: true };
    },

    success: (state: {}) => {
      return {
        ...state,
        isSignUpSuccess: true,
        isLoadingSignUp: false,
        isInvalidPasswordError: false,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      const errorMessage = (error.error as Error).message;

      return {
        ...state,
        error: error,
        isSignUpSuccess: false,
        isLoadingSignUp: false,
        isInvalidPasswordError: errorMessage === 'InvalidPasswordError',
      };
    },
  },
  {
    name: CONFIRMATION_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return { ...state, payload };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        confirmationResponse: payload as ConfirmationResponse,
        isConfirmationSuccess: true,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return { ...state, error: error, isConfirmationSuccess: false };
    },
    reset: (state: {}) => {
      return { ...state, isConfirmationSuccess: false };
    },
  },
  {
    name: FORGOT_PASSWORD_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;
      return {
        ...state,
        payload,
        isLoadingSendForgotPasswordEmail: true,
        sendEmailForForgotPasswordStatus: REQUEST_STATUS.REQUEST,
      };
    },
    success: (state: {}) => {
      return {
        ...state,
        isLoadingSendForgotPasswordEmail: false,
        isForgotPasswordEmailSuccess: true,
        sendEmailForForgotPasswordStatus: REQUEST_STATUS.SUCCESS,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        isLoadingSendForgotPasswordEmail: false,
        isForgotPasswordEmailSuccess: false,
        sendEmailForForgotPasswordStatus: REQUEST_STATUS.FAIL,
        error: error,
      };
    },
    reset: (state: {}) => {
      return {
        ...state,
        isForgotPasswordEmailSuccess: false,
        sendEmailForForgotPasswordStatus: REQUEST_STATUS.REQUEST,
      };
    },
  },
  {
    name: RESET_PASSWORD_USE_CASE,
    executing: (state: {}, action: Action) => {
      const { payload } = action;

      return { ...state, payload, isLoadingResetPassword: true };
    },
    success: (state: {}, action: Action) => {
      const { payload } = action;

      return {
        ...state,
        isLoadingResetPassword: false,
        isResetPasswordSuccess: true,
        resetPasswordResponse: payload as ResetPasswordResponse,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;

      return {
        ...state,
        isLoadingResetPassword: false,
        isResetPasswordSuccess: false,
        error: error,
      };
    },
    reset: (state: {}) => {
      return {
        ...state,
        isResetPasswordSuccess: false,
      };
    },
  },
]);
