export const SIGN_IN_USE_CASE = "SIGN_IN_USE_CASE";
export const SIGN_IN_BY_GOOGLE_USE_CASE = "sign-in-by-google-use-case";
export const SIGN_UP_USE_CASE = "sign-up-use-case";
export const CONFIRMATION_USE_CASE = "CONFIRMATION_USE_CASE";
export const FORGOT_PASSWORD_USE_CASE = "FORGOT_PASSWORD_USE_CASE";
export const RESET_PASSWORD_USE_CASE = "RESET_PASSWORD_USE_CASE";

export const GET_USER_BY_ID_USE_CASE = "GET_USER_BY_ID_USE_CASE";
export const UPDATE_PROFILE_USE_CASE = "UPDATE_PROFILE_USE_CASE";
export const CHANGE_PASSWORD_USE_CASE = "CHANGE_PASSWORD_USE_CASE";
